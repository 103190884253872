/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import 'alpinejs'
import '@claviska/jquery-minicolors'

window.Trix = require('trix')
window.Trix.config.blockAttributes.heading1 = { tagName: 'h2' }

require('./modules/credit-card-form');
require('./modules/ajax-forms');
require('./modules/clipboard');

var App = (function($) {
  var $emails = $('.form_email'),
      $editors = $emails.find('trix-editor[input*="body_"]'),
      $alerts = $('.error_message, .alert_message')
      
  if ($('.color_input').length > 0) {
    $('.color_input').minicolors({theme: 'bootstrap'});
  }
  
  if ($emails.length > 0) {
    $emails.submit(function(){
      window.onbeforeunload =  null
    })
  }

  if ($editors.length > 0) {
      $editors.each(function(){
          var element = $(this).get(0)
          var email_id = element.getAttribute('data-email-id')

          element.editor.insertHTML(eval('window.email_body_' + email_id))
          element.addEventListener('trix-change', changes_made)
      })
  }
  
  if ($alerts.length > 0) {
    setTimeout(function(){ 
      $alerts.fadeOut() 
    }, 1500)
  }

  var changes_made = function() {
    window.onbeforeunload = function() { return true }
  }
  
  if (document.querySelectorAll('[data-pricing-scale]').length > 0) {
    var slider = document.getElementById('pricing_slider'),
        slider_value = document.getElementById('pricing_slider_value'),
        pricing_scales = document.querySelectorAll('[data-pricing-scale]');
        
    function format(number){
      var symbols = ['', 'K', 'M'],
          tier = Math.log10(number) / 3 | 0;

      if (tier === 0) return number;

      return (number / Math.pow(10, tier * 3)).toFixed() + (tier > 1 ? 'M' : 'K');
    }
    
    slider.addEventListener('input', function(){ 
      var value = this.value
      
      slider_value.innerHTML = format(value)
      
      for (var i=0; i<pricing_scales.length; i++) {
        var element = pricing_scales[i],
            scale = JSON.parse(element.dataset.pricingScale),
            price = 0
        
        for (var mrr in scale) {
          if (parseInt(mrr) <= parseInt(value)) {
            price = scale[mrr]
          }
        }
        
        pricing_scales[i].innerHTML = price
      }
    })
  }
  
})(jQuery);
