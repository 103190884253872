var AjaxForms = (function($) {
  $('form').each(function(){
    $(this).submit(function(e){
      var $form           = $(this),
          data            = {},
          fields          = $form.serializeArray(),
          persist_message = false,
          $button         = $('[form=' + $form.attr('id') + ']').length > 0 ? $('[form=' + $form.attr('id') + ']') : $form.find('  input[type="submit"]')
         
      if (
        $form.hasClass('no-ajax') || 
        ($form.attr('id') == 'payment-form' && $form.find('[name="stripe_token"]').length == 0)
      ) {
        return true
      }
    
      e.preventDefault()
    
      if (!$button.data('initial-value')) {
        if ($button.is('button')) {
           $button.data('initial-value', $button.html())
        } else {
          $button.data('initial-value', $button.val())
        }
      }
    
      if ($form.find('[required]').val() && $form.find('[required]').val().trim().length <= 0) {
        $form.find('[required]').addClass('is-invalid').focus(function(){ $(this).removeClass('is-invalid') })
        show_message('Please provide required fields.', true)
        return false;
      }
    
      for (var i=0; i<fields.length; i++) {
        var name = fields[i].name
        if (name.indexOf('[]') > 0) {
          name = name.replace('[]', '')
          if (typeof data[name] == 'undefined') data[name] = []
          data[name][data[name].length] = fields[i].value
        } else {
          data[name] = fields[i].value
        }
      }
    
      $button
        .prop('disabled', true)
        .val($button.data('saving') || 'Thinking...')
        .html($button.data('saving') || 'Thinking...')
    
      axios({
        method: $form.attr('method') || 'GET',
        url: $form.attr('action'),
        data: data
      })
      .then(response => {
        var message = 'Boom! Success 🤘';
    
        $form.trigger('ajax-success', [$form, response])
    
        if (response.data.message || response.data.message === false) {
          message = response.data.message
        }
    
        if (response.data.redirect_url) {
          persist_message = true;
          setTimeout(function(){
            if (response.data.redirect_url === true) {
              window.location.reload()
            } else {
              window.location.href = response.data.redirect_url
            }
          }, 1000)
        }
    
        show_message(message)
    
        reset_form($form)
      })
      .catch(error => {
        var message = 'Whoops! Something went wrong.',
          response = error.response
          
        $form.trigger('ajax-error', [$form, response])
    
        if (response.data.errors && Object.keys(response.data.errors).length > 0) {
          var $field = $(`[name="${Object.keys(response.data.errors)[0]}"]`)
          if ($field.length > 0) {
            $field.addClass('is-invalid').change(function(){ $(this).removeClass('is-invalid') })
          }
          message = response.data.errors[Object.keys(response.data.errors)[0]][0];
        } else if (response.data.message || response.data.message === false) {
          message = response.data.message
        }
    
        show_message(message, true)
        reset_form($form)
      })
    
      return false;
    })
  })
  
  var reset_form = function($form) {
    var $button = $('[form=' + $form.attr('id') + ']').length > 0 ? $('[form=' + $form.attr('id') + ']') : $form.find('input[  type="submit"]')
    
    // Give it just a sec so it isn't so hacky looking
    setTimeout(function(){
      if ($button.is('button')) {
        $button.html($button.data('initial-value'))
      } else {
        $button.val($button.data('initial-value'))
      }
    
      $button.prop('disabled', false)
    }, 400)
  }
  
  var show_message = function(message, is_error, persist) {
    if (message === false) return false;
    
    $('body').prepend('<div id="alert_message" class="alert_message fixed top-0 left-0 mt-6 ml-6 ' + (is_error ? 'bg-red-600' : 'bg-purple-600') + ' text-white px-4 py-3 mb-5">' + message + '</div>')
    
    $('#alert_message').fadeIn(function(){
      if (!persist) {
        setTimeout(function(){
          $('*#alert_message').fadeOut(function(){
            $(this).remove();
          })
        }, 2500)
      }
    })
  }
})(jQuery);
