import ClipboardJS from 'clipboard'

var Clipboard = (function($) {
  if ($('.copy-to-clipboard').length) {
    new ClipboardJS('.copy-to-clipboard').on('success', function(e){
      var $button = $(e.trigger),
          text = $button.data('initial-value', $button.html())
          
      $button.html('Copied!')
      setTimeout(function(){ $button.html($button.data('initial-value')) }, 1000)
    })
  }
})(jQuery);
